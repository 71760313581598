import React from "react";
import "./Skills.css";

function Skills() {
  return (
    <div className="skills-section">
      <h2 className="section-title">SKILLS</h2>
      <div className="gradient-divider"></div>
      <div className="skills-section-table">
        <p className="section-description">
          Technologies I've used in projects or have experience with.
        </p>
        <div className="skills-table">
          <div className="skills-row">
            <span className="category">Programming Languages:</span>
            <div className="skills-list">
              <i className="devicon-python-plain colored"></i>
              <i className="devicon-java-plain colored"></i>
              <i className="devicon-javascript-plain colored"></i>
              <i className="devicon-html5-plain colored"></i>
              <i className="devicon-css3-plain colored"></i>
            </div>
          </div>

          <div className="skills-row">
            <span className="category">Frameworks & Libraries:</span>
            <div className="skills-list">
              <i className="devicon-django-plain colored"></i>
              <i className="devicon-react-original colored"></i>
              <i className="devicon-vuejs-plain colored"></i>
              <i className="devicon-nuxtjs-plain colored"></i>
            </div>
          </div>

          <div className="skills-row">
            <span className="category">Tools & Platforms:</span>
            <div className="skills-list">
              <i className="devicon-git-plain colored"></i>
              <img
                src="image/vscode.svg"
                alt="VS Code"
                className="custom-icon"
              />
              <i className="devicon-intellij-plain colored"></i>
              <i className="devicon-bootstrap-plain colored"></i>
            </div>
          </div>

          <div className="skills-row">
            <span className="category">Databases:</span>
            <div className="skills-list">
              <i className="devicon-mysql-plain colored"></i>
              <i className="devicon-postgresql-plain colored"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
