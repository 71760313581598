import React from "react";
import "./Resume.css";

const Resume = () => {
  const educationAndExperience = [
    {
      date: "2020 Augest- 2024 Augest",
      title: "Bachelor of Arts in Data Science",
      organization: "University of California, Berkeley",
    },
    {
      date: "2024 May - June",
      title: "Web Developer",
      organization: "Metastudy",
      description:
        "Delaware, United States\nDeveloped and launched a student-focused web service integrating SAT/ACT compatibility and personalized result analysis, now adopted by several high schools in the US.",
    },
    {
      date: "2023 June- July",
      title: "Web Development Intern",
      organization: "WITTGEN BIOTECHNOLOGI",
      description:
        "Automated email threat detection pipelines using Python and Jenkins.",
    },
    {
      date: "Summer 2022",
      title: "SWE Intern - Risk Data Mining",
      organization: "TikTok",
      description:
        "Enhanced business risk detection systems and implemented empirical evaluation metrics.",
    },
    {
      date: "Summer 2018",
      title: "Communications Intern",
      organization: "Shinjin Furnace Machinery Co. Ltd.",
      description:
        "Handled international communications for North America and East Asia.",
    },
  ];

  const awardsAndCertifications = [
    {
      date: "May 2016",
      title: "우수논문상 수상",
      organization: "한국멀티미디어학회 춘계학술대회",
      description:
        "데이터마이닝과 통계적 학습을 활용한 주가변화 예측 논문으로 선정.",
    },
    {
      date: "2021",
      title: "Dean's List",
      organization: "UC Berkeley",
      description: "College of Letters & Science.",
    },
    {
      date: "2018",
      title: "Global Youth Entrepreneurship Challenge (GYEC)",
      organization: "Korea Business Competition",
      description:
        "Proposed a competitive employment model for Shinsegae Food.",
    },
    {
      date: "2018",
      title: "NSDA Korea National Championship",
      organization: "3rd Place",
      description: "Lincoln-Douglas Debate.",
    },
  ];

  return (
    <div className="resume-section">
      <h2 className="section-title">Resume</h2>
      <p className="section-description">
        A brief overview of my education, experience, and accomplishments.
      </p>

      <div className="resume-columns">
        {/* Education & Experience */}
        <div className="resume-column">
          <h3 className="column-title">Education & Experience</h3>
          <div className="timeline">
            {educationAndExperience.map((item, index) => (
              <div key={index} className="timeline-item">
                <div className="timeline-date">{item.date}</div>
                <div className="timeline-content">
                  <h4 className="timeline-title">{item.title}</h4>
                  <p className="timeline-organization">{item.organization}</p>
                  <p className="timeline-description">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Awards & Other Activities */}
        <div className="resume-column">
          <h3 className="column-title">Awards & Other Activities</h3>
          <div className="timeline">
            {awardsAndCertifications.map((item, index) => (
              <div key={index} className="timeline-item">
                <div className="timeline-date">{item.date}</div>
                <div className="timeline-content">
                  <h4 className="timeline-title">{item.title}</h4>
                  <p className="timeline-organization">{item.organization}</p>
                  <p className="timeline-description">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
