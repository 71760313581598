import Header from './components/Header';
import About from './components/About';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Resume from './components/Resume';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link, Element } from 'react-scroll';
import { TiHomeOutline } from "react-icons/ti";
import { MdOutlinePerson } from "react-icons/md";
import { TbWriting } from "react-icons/tb";
import { GoProject } from "react-icons/go";
import { LuNewspaper } from "react-icons/lu";

import './App.css';

function App() {
  return (
    <div className="App">
      <nav className="navbar">
        <Link to="header" smooth={true} duration={500} spy={true} className="nav-icon" activeClass="active" > 
          <TiHomeOutline className="icon" />
          <span className="nav-text">Home</span>
        </Link>
        <Link to="about" smooth={true} duration={500} spy={true} className="nav-icon" activeClass="active">
          <MdOutlinePerson className="icon" />
          <span className="nav-text">About</span>
        </Link>
        <Link to="skills" smooth={true} duration={500} spy={true} className="nav-icon" activeClass="active">
          <TbWriting className="icon" />
          <span className="nav-text">Skills</span>
        </Link>
        <Link to="projects" smooth={true} duration={500} spy={true} className="nav-icon" activeClass="active">
          <GoProject className="icon" />
          <span className="nav-text">Projects</span>
        </Link>
        <Link to="resume" smooth={true} duration={500} spy={true} className="nav-icon" activeClass="active">
          <LuNewspaper className="icon" />
          <span className="nav-text">Resume</span>
        </Link>
      </nav>
      
      <Element name="header">
        <Header />
      </Element>
      
      <Element name="about">
        <About />
      </Element>

      <Element name="skills">
        <Skills />
      </Element>      

      <Element name="projects">
        <Projects />
      </Element>
      
      <Element name="resume">
        <Resume />
      </Element>

      <Element name="footer">
        <Footer />
      </Element>
    </div>
  );
}

export default App;
