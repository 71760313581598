import React, { useEffect, useRef } from "react";
import "./About.css";

function About() {
  const aboutSectionRef = useRef(null);

  useEffect(() => {
    const sectionRef = aboutSectionRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (sectionRef) {
      observer.observe(sectionRef);
    }

    return () => {
      if (sectionRef) {
        observer.unobserve(sectionRef);
      }
    };
  }, []);

  return (
    <div className="about-section" ref={aboutSectionRef}>
      <h2 className="section-title">ABOUT</h2>
      <div className="gradient-divider"></div>
    <div className="text-section">
      <p className="intro-text">
        Hello World! I am a UC Berkeley graduate in Data Science with a focus on
        creative problem-solving and effective team collaboration — you can view
        my resume{" "}
        <a
          href={`${process.env.PUBLIC_URL}/pdf/resume.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>

      <div className="about-content">
        <div className="summary">
          <h3 className="job-title">
            Software Engineer, Data Analysis and Web Development
          </h3>
          <p className="description">
            I am a recent graduate in Data Science from UC Berkeley, with
            experience in developing data-driven solutions and enhancing web
            applications. I previously interned as a Web Developer at&nbsp;
            <a
              href="https://www.metastudy.ai/"
              target="_blank"
              rel="noopener noreferrer"
              className="link-icon"
            >
              MetaStudy.ai
            </a>
            ,&nbsp; where I expanded testing platforms for high school students,
            and as a Web Development Intern at&nbsp;
            <a
              href="https://www.wittgenbio.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="link-icon"
            >
              WITTGEN BIOTECHNOLOGIES
            </a>
            , focusing on improving client-facing portals and internal tools for
            operational efficiency.
          </p>
        </div>

        <div className="contact-info">
          <div className="contact-row">
            <i className="fas fa-map-marker-alt contact-icon"></i>
            <span className="contact-label">Location:</span> Seoul, South Korea
          </div>
          <div className="contact-row">
            <i className="fas fa-phone contact-icon"></i>
            <span className="contact-label">Phone(KR):</span> +82 (010) - 6274 -
            ****
          </div>
          <div className="contact-row">
            <i className="fas fa-phone contact-icon"></i>
            <span className="contact-label">Phone(US):</span> +1 (510) - 423 -
            ****
          </div>
          <div className="contact-row">
            <i className="fas fa-envelope contact-icon"></i>
            <span className="contact-label">Email:</span> xoem1012@gmail.com
          </div>
          <div className="contact-row">
            <i className="fas fa-graduation-cap contact-icon"></i>
            <span className="contact-label">Major:</span> Data Science
          </div>
          <div className="contact-row">
            <i className="fas fa-user-graduate contact-icon"></i>
            <span className="contact-label">Graduation Year:</span> 2024
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default About;
