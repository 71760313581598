import React, { useState } from "react";
import "./Projects.css";

const projectData = [
  {
    id: 1,
    title: "SAT/ACT Web Service",
    category: "Corporate",
    description:
      "Developed and launched a student-focused web service integrating SAT/ACT compatibility and personalized result analysis, now adopted by several high schools in the US.",
    tools: ["Vue.js", "Nuxt.js", "JavaScript"],
    image: "sat-act.jpg", // Example file, ensure it's in your public/images folder
  },
  {
    id: 2,
    title: "BYOW - Build Your Own World",
    category: "Course",
    description:
      "A Java-based game that allows players to build and explore their unique virtual world.",
    tools: ["Java"],
    image: "byow.jpg",
  },
  {
    id: 3,
    title: "Metaport 3D Visualization",
    category: "Personal",
    description:
      "A personal project visualizing architectural designs using SketchUp.",
    tools: ["SketchUp"],
    image: "metaport.jpg",
  },
  // Add more projects...
];

const categories = ["All", "Corporate", "Course", "Personal"];

const Projects = () => {
  const [activeCategory, setActiveCategory] = useState("All");

  const filteredProjects =
    activeCategory === "All"
      ? projectData
      : projectData.filter((project) => project.category === activeCategory);

  return (
    <div className="projects-section">
      <h2 className="section-title">Projects</h2>
      <div className="categories">
        {categories.map((category) => (
          <button
            key={category}
            className={`category-btn ${
              activeCategory === category ? "active" : ""
            }`}
            onClick={() => setActiveCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="projects-grid">
        {filteredProjects.map((project) => (
          <div key={project.id} className="project-card">
            <img
              src={`images/${project.image}`}
              alt={project.title}
              className="project-image"
            />
            <div className="project-info">
              <h3 className="project-title">{project.title}</h3>
              <p className="project-description">{project.description}</p>
              <div className="project-tools">
                {project.tools.map((tool, index) => (
                  <span key={index} className="tool-badge">
                    {tool}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
